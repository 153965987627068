@import url("https://fonts.cdnfonts.com/css/myriad-pro");

* {
  margin: 0;
  padding: 0;
  font-family: "Myriad Pro", sans-serif;
  font-weight: 200;
}

.main__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 23.25px;
  transform-origin: top;
  height: 100%;
  user-select: none; /* Запрещаем выделение текста */
  pointer-events: auto; /* Включаем события указателя для контейнера */
}

.main__container-logo {
  width: 375px;
  margin-block: 29.25px 48.75px;
}

.main__container-title {
  font-size: 56.25pt;
  color: #204596;
  text-transform: uppercase;
}

.main__container-text {
  font-size: 26.25pt;
  color: #939393;
  margin-block: 7.5px;
}

.main__container-line {
  width: 100%;
  max-width: 1350px;
}

.main__container-description {
  font-size: 18.75pt;
  color: #939393;
}

.main__container-email,
.main__container-phone {
  color: inherit; /* Убираем цвет ссылки, наследуем цвет родительского элемента */
  text-decoration: none; /* Убираем подчеркивание */
  cursor: pointer; /* Устанавливаем курсор в виде указателя при наведении */
}

.main__container-main {
  width: 525px;
}

.main__container-gear {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
}

.main__container-gear-left,
.main__container-gear-right {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  position: relative;
}

.main__container-gear-left .main__container-gear-dark {
  bottom: 247.5px;
  right: 135px;
  animation-direction: reverse;
}

.main__container-gear-left .main__container-gear-light {
  bottom: 277.5px;
  right: 221px;
  width: 75px;
}

.main__container-gear-right .main__container-gear-light {
  bottom: 112.5px;
  left: 337.5px;
  width: 75px;
  animation-direction: reverse;
}

.main__container-gear-right .main__container-gear-dark {
  bottom: 187.5px;
  left: 102.5px;
  width: 150px;
}

.main__container-gear-dark,
.main__container-gear-light {
  position: absolute;
  width: 37.5px;
  animation: rotate 30s linear infinite;

}

a {
  transition: color 0.5s ease;
}

a:hover {
  color: #204596;
}

/*Анимации*/
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}



/* Адаптивные стили */
@media (max-width: 1200px) {
  .main__container-logo {
    width: 400px;
  }

  .main__container-title {
    font-size: 60pt;
  }

  .main__container-text {
    font-size: 30pt;
  }

  .main__container-main {
    width: 600px;
  }

  .main__container-gear-left .main__container-gear-dark {
    bottom: 280px;
    right: 150px;
  }

  .main__container-gear-left .main__container-gear-light {
    bottom: 320px;
    right: 250px;
  }

  .main__container-gear-right .main__container-gear-light {
    bottom: 120px;
    left: 400px;
  }

  .main__container-gear-right .main__container-gear-dark {
    bottom: 220px;
    left: 100px;
  }
}

@media (max-width: 900px) {
  .main__container-logo {
    width: 300px;
  }

  .main__container-title {
    font-size: 50pt;
  }

  .main__container-text {
    font-size: 25pt;
  }

  .main__container-main {
    width: 500px;
  }

  .main__container-gear-right,
  .main__container-gear-left {
    display: none;
  }
}

@media (max-width: 600px) {
  .main__container {
    margin: 20px;
  }

  .main__container-logo {
    width: 100%;
    max-width: 250px;
  }

  .main__container-title {
    font-size: 40pt;
  }

  .main__container-text {
    font-size: 20pt;
  }

  .main__container-main {
    width: 100%;
    width: 400px;
  }
}
